// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/vercel/path0/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("/vercel/path0/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-litjen-adventskalender-index-js": () => import("/vercel/path0/src/pages/litjen-adventskalender/index.js" /* webpackChunkName: "component---src-pages-litjen-adventskalender-index-js" */),
  "component---src-pages-litjen-adventskalender-luke-1-8-lc-ou-js": () => import("/vercel/path0/src/pages/litjen-adventskalender/luke-1-8LCOu.js" /* webpackChunkName: "component---src-pages-litjen-adventskalender-luke-1-8-lc-ou-js" */),
  "component---src-pages-litjen-adventskalender-luke-10-nno-gy-js": () => import("/vercel/path0/src/pages/litjen-adventskalender/luke-10-NnoGy.js" /* webpackChunkName: "component---src-pages-litjen-adventskalender-luke-10-nno-gy-js" */),
  "component---src-pages-litjen-adventskalender-luke-11-w-cue-k-js": () => import("/vercel/path0/src/pages/litjen-adventskalender/luke-11-wCueK.js" /* webpackChunkName: "component---src-pages-litjen-adventskalender-luke-11-w-cue-k-js" */),
  "component---src-pages-litjen-adventskalender-luke-12-xw-uhc-js": () => import("/vercel/path0/src/pages/litjen-adventskalender/luke-12-XwUhc.js" /* webpackChunkName: "component---src-pages-litjen-adventskalender-luke-12-xw-uhc-js" */),
  "component---src-pages-litjen-adventskalender-luke-13-i-nns-6-js": () => import("/vercel/path0/src/pages/litjen-adventskalender/luke-13-iNNS6.js" /* webpackChunkName: "component---src-pages-litjen-adventskalender-luke-13-i-nns-6-js" */),
  "component---src-pages-litjen-adventskalender-luke-14-qx-nd-9-js": () => import("/vercel/path0/src/pages/litjen-adventskalender/luke-14-qxND9.js" /* webpackChunkName: "component---src-pages-litjen-adventskalender-luke-14-qx-nd-9-js" */),
  "component---src-pages-litjen-adventskalender-luke-15-gq-auh-js": () => import("/vercel/path0/src/pages/litjen-adventskalender/luke-15-GQAuh.js" /* webpackChunkName: "component---src-pages-litjen-adventskalender-luke-15-gq-auh-js" */),
  "component---src-pages-litjen-adventskalender-luke-16-4-f-u-1-f-js": () => import("/vercel/path0/src/pages/litjen-adventskalender/luke-16-4fU1F.js" /* webpackChunkName: "component---src-pages-litjen-adventskalender-luke-16-4-f-u-1-f-js" */),
  "component---src-pages-litjen-adventskalender-luke-17-nwo-7-a-js": () => import("/vercel/path0/src/pages/litjen-adventskalender/luke-17-NWO7A.js" /* webpackChunkName: "component---src-pages-litjen-adventskalender-luke-17-nwo-7-a-js" */),
  "component---src-pages-litjen-adventskalender-luke-18-3-nxo-3-js": () => import("/vercel/path0/src/pages/litjen-adventskalender/luke-18-3nxo3.js" /* webpackChunkName: "component---src-pages-litjen-adventskalender-luke-18-3-nxo-3-js" */),
  "component---src-pages-litjen-adventskalender-luke-19-m-rsx-a-js": () => import("/vercel/path0/src/pages/litjen-adventskalender/luke-19-MRsxA.js" /* webpackChunkName: "component---src-pages-litjen-adventskalender-luke-19-m-rsx-a-js" */),
  "component---src-pages-litjen-adventskalender-luke-2-lff-lm-js": () => import("/vercel/path0/src/pages/litjen-adventskalender/luke-2-lffLm.js" /* webpackChunkName: "component---src-pages-litjen-adventskalender-luke-2-lff-lm-js" */),
  "component---src-pages-litjen-adventskalender-luke-20-g-gxj-c-js": () => import("/vercel/path0/src/pages/litjen-adventskalender/luke-20-gGxjC.js" /* webpackChunkName: "component---src-pages-litjen-adventskalender-luke-20-g-gxj-c-js" */),
  "component---src-pages-litjen-adventskalender-luke-21-r-7-v-nd-js": () => import("/vercel/path0/src/pages/litjen-adventskalender/luke-21-R7VNd.js" /* webpackChunkName: "component---src-pages-litjen-adventskalender-luke-21-r-7-v-nd-js" */),
  "component---src-pages-litjen-adventskalender-luke-22-f-xjkx-js": () => import("/vercel/path0/src/pages/litjen-adventskalender/luke-22-fXjkx.js" /* webpackChunkName: "component---src-pages-litjen-adventskalender-luke-22-f-xjkx-js" */),
  "component---src-pages-litjen-adventskalender-luke-23-xm-2-bu-js": () => import("/vercel/path0/src/pages/litjen-adventskalender/luke-23-xm2Bu.js" /* webpackChunkName: "component---src-pages-litjen-adventskalender-luke-23-xm-2-bu-js" */),
  "component---src-pages-litjen-adventskalender-luke-24-5-mc-rz-js": () => import("/vercel/path0/src/pages/litjen-adventskalender/luke-24-5mcRZ.js" /* webpackChunkName: "component---src-pages-litjen-adventskalender-luke-24-5-mc-rz-js" */),
  "component---src-pages-litjen-adventskalender-luke-3-s-5-tj-m-js": () => import("/vercel/path0/src/pages/litjen-adventskalender/luke-3-S5tjM.js" /* webpackChunkName: "component---src-pages-litjen-adventskalender-luke-3-s-5-tj-m-js" */),
  "component---src-pages-litjen-adventskalender-luke-4-m-xvs-o-js": () => import("/vercel/path0/src/pages/litjen-adventskalender/luke-4-MXvsO.js" /* webpackChunkName: "component---src-pages-litjen-adventskalender-luke-4-m-xvs-o-js" */),
  "component---src-pages-litjen-adventskalender-luke-5-k-5-w-xc-js": () => import("/vercel/path0/src/pages/litjen-adventskalender/luke-5-k5wXc.js" /* webpackChunkName: "component---src-pages-litjen-adventskalender-luke-5-k-5-w-xc-js" */),
  "component---src-pages-litjen-adventskalender-luke-6-jm-9-zz-js": () => import("/vercel/path0/src/pages/litjen-adventskalender/luke-6-JM9ZZ.js" /* webpackChunkName: "component---src-pages-litjen-adventskalender-luke-6-jm-9-zz-js" */),
  "component---src-pages-litjen-adventskalender-luke-7-q-8-qv-g-js": () => import("/vercel/path0/src/pages/litjen-adventskalender/luke-7-Q8qvG.js" /* webpackChunkName: "component---src-pages-litjen-adventskalender-luke-7-q-8-qv-g-js" */),
  "component---src-pages-litjen-adventskalender-luke-8-ne-x-at-js": () => import("/vercel/path0/src/pages/litjen-adventskalender/luke-8-neXAt.js" /* webpackChunkName: "component---src-pages-litjen-adventskalender-luke-8-ne-x-at-js" */),
  "component---src-pages-litjen-adventskalender-luke-9-l-bq-de-js": () => import("/vercel/path0/src/pages/litjen-adventskalender/luke-9-LBqDe.js" /* webpackChunkName: "component---src-pages-litjen-adventskalender-luke-9-l-bq-de-js" */),
  "component---src-pages-velkommen-js": () => import("/vercel/path0/src/pages/velkommen.js" /* webpackChunkName: "component---src-pages-velkommen-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/vercel/path0/.cache/data.json")

